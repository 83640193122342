.modal{
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
    background-color: rgba(0,0,0,0.4);
  }
  .modal.active{
    opacity: 1;
    pointer-events: all;
    z-index: 5;
  }
  .modal_content{
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    width: 100vw;
    height: 70%;
    transform: scale(0.5);
    transition: 0.4s all;
    position: absolute;
    z-index: 5;
  }
  .modal_content.active{
    transform: scale(1);
  }
