@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #F9FCFF;
}
.bg-with-image {
    background-image: url('./assets/hand.png');
    background-size: cover; /* Масштабирование изображения, чтобы полностью покрыть элемент */
    background-repeat: no-repeat; /* Изображение не будет повторяться */
    background-position: center; /* Центрирование изображения */
  }
@keyframes slideLeft {
    0% {
        transform: translateX(100%); /* Начальная позиция вне экрана справа */
    }
    100% {
        transform: translateX(-100%); /* Конечная позиция вне экрана слева */
    }
}

/* Применяем анимацию к элементам */
.animated-slide {
    display: inline-block; /* Элементы должны быть в строке, чтобы анимация работала правильно */
    animation: slideLeft 10s linear infinite; /* 10 секунд на полный цикл, постоянное движение */
    white-space: nowrap; /* Убедитесь, что элементы не обрываются на новой строке */
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.carousel {
  display: flex;
  animation: scroll 20s linear infinite;
}
